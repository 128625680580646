import React, { Suspense, useEffect, useState } from 'react'

import { LogoLoading } from "../components/Loading";
import { BrowserRouter } from 'react-router-dom';
import {useMatomo } from '@jonkoops/matomo-tracker-react'



const NormalRoutes = React.lazy(()=>import('./NormalRoutes'));
const Header = React.lazy(()=>import('../components/Header'));
const Footer = React.lazy(()=>import('../components/Footer'));

const BaseRoutes = () => {
    const [internalLinks, setInternalLinks] = useState(false); //if true, it means we are creating links internally
    const [showNavAndFooter,setShowNavAndFooter]=useState(true)
    const {trackPageView}=useMatomo();


    // useEffect(()=>{
    //     trackPageView({
    //         documentTitle: window.location.pathname, // optional
    //         href: window.location.href, // optional
    //     })
    // },[])
    return (
        <Suspense fallback={<LogoLoading />}>
            <div className="h-full flex flex-col">
                {
                    // showNavAndFooter&&
                        <div className="flex-shrink-0 h-[80px]">
                            <Header internalLinks={internalLinks}/>
                        </div>
                }
                <div className="flex-grow pt-2 md:pt-0 ">
                    <NormalRoutes setInternalLinks={setInternalLinks} setShowNavAndFooter={setShowNavAndFooter} />
                </div>
                {
                    // showNavAndFooter&&
                        <div className="flex-shrink-0">
                            <Footer/>
                        </div>
                }
            </div>

        </Suspense>
    )
}

export default BaseRoutes