import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    
}



const countrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {
        set_country_listing:(state,action)=>{
            state = action.payload
            return state
        },
        reset_countries_listing:(state,action)=>{
            state=initialState
            return state
        }

    }
})

export const {set_country_listing,reset_countries_listing} = countrySlice.actions;
export default countrySlice.reducer;
