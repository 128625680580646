import React, {createContext, useContext, useEffect} from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {GrClose} from "react-icons/gr";
import {POPUP_FULL_SCREEN, POPUP_MEDIUM,POPUP_SMALL} from '../../constant'
import { useDetectClickOutside } from 'react-detect-click-outside';
//global context for popup
export const BasePopupContext = createContext(null);

// initial state for popup
const initialState = {
    component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
}

const BasePopup = () => {

    //extracting state from context
    const {popupState, setPopup} = useContext(BasePopupContext);
    const {component, heading, size, visible, contained, hideDefaultClose} = popupState;

    const hidePopup = () => {
        setPopup({visible: false, hideDefaultClose: false});
    }

    // this is a helper component which sets the base for a modal
    const variants = {
        initial: {
            opacity: 0,
            // scale:0.6,
            top: "100%",
        },
        enter: {
            opacity: 1,
            // scale:1,
            top: 0,

        },
        exit: {
            opacity: 0,
            top:"-100%",
            // scale:0.6,

        }
    }

    const sizeClass = size === POPUP_SMALL ? "w-full md:w-96 lg:w-6/12 xl:w-5/12 2xl:w-3/12 max-w-[500px]" : size === POPUP_MEDIUM ? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]" :
    size===POPUP_FULL_SCREEN?'w-full h-full':"w-10/12 max-w-container";
    return (
            <AnimatePresence>
                    {visible &&
                        <PopupContent 
                            hideDefaultClose={hideDefaultClose}
                            variants={variants}
                            heading={heading}
                            contained={contained}
                            hidePopup={hidePopup}
                            component={component}
                            sizeClass={sizeClass}
                            size={size}
                            setPopup={setPopup}
                        />
                    }
            </AnimatePresence>
    )
}

const PopupContent = ({variants, heading, contained, hideDefaultClose, hidePopup, component, sizeClass, setPopup,size}) => {
    useEffect(() => {
        // return () => {
        //     // reset popup to initial state on unmount
        //     setPopup(initialState)
        // }
    })
    

    return(
        <div className={` ${size===POPUP_FULL_SCREEN?"":"bg-black pt-16 pb-8" }  bg-opacity-30 left-0 top-0 fixed h-screen w-screen flex items-start  justify-center z-[1000] overflow-y-auto`}>
            <motion.div
            transition={{type: "tween"}}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={variants}
            className={`${sizeClass} bg-white shadow-lg rounded-lg border-1 border-gray-200 relative mx-auto`}>
                {/* header */}
                {
                        heading&&
                        <div className="rounded-lg rounded-b-none p-5 flex items-center justify-between border-b-[1px] border-gray-200 bg-white">
                            
                            <p className="text-xl text-black font-bold ">
                                {heading}
                            </p>
                            <div className={`text-black text-2xl cursor-pointer ${hideDefaultClose && "hidden"}`} onClick={hidePopup}>
                                <GrClose/>
                            </div>
                        </div>
                }
                {/* header */}
                
                <div className={contained ? "px-5 py-8" : ""} >
                    {component}
                </div>
            </motion.div>
        </div>
    )
}

export default BasePopup
