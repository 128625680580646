// export const BASE_URL = "http://192.168.18.191:8000/";//tayyab Mac Pro id
// export const BASE_URL = "http://192.168.18.177:8011/";//ashar Pro id
export const BASE_URL = "https://api.pro-id.co/" //production

export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";
export const POPUP_FULL_SCREEN='full-screen'


export const hidePopup = (setPopup) => {
    setPopup({visible: false, hideDefaultClose: false});
}



export const YELLOW='#f9ff00'
export const BLACK='#000000'
export const BLUE='#0081ff'
export const WHITE='#ffffff'
export const PINK='#ff00b8'
export const RED='#f00d0d'


export const COLORS=[
    {
        name:"Red",
        value:RED
    },
    {
        name:"Pink",
        value:PINK
    },
    {
        name:"Blue",
        value:BLUE
    },
    {
        name:"Yellow",
        value:YELLOW
    },
    {
        name:"White",
        value:WHITE
    },
    {
        name:"Black",
        value:BLACK
    },
]

export const FONT_MEDIUM=65
export const FONT_SMAL=50
export const FONT_LARGE=75

export const BOLD='bold'
export const ITALIC='italic'


// matomo configuration variables

export const MATOMO_SITE_ID = 0
export const MATOMO_USER_ID = ''
export const MATOMO_URL = ''

// matomo configuration variables


export const THEME_PRIMARY = "#40af49"

export const CONTRIES_OPTIONS = [
    {value:"pakistan", label:"Pakistan" },
]
export const CITIES_OPTIONS = [
    {value:"lahore", label:"Lahore" },
    {value:"outside", label:"Out of Lahore " },
]
export const BOLEAN_OPTIONS = [
    {  value: true , label: "Yes" },
    { value: false, label: "No" },
];


export const BANK_TRANSFER="bank-transfer"
export const CARD_PAYMENT="stripe"
export const CAHS_ON_DELIVER="cod"



export const LAHORE="lahore"

export const GENERIC_COUPON_CODE="12J5PJ"

export const PREVIOUS_ORDER_URL ="/my-previous-order"
export const TRACKING_ORDER_URL ="/tracking"
export const PRO_ID_PERVIOUES_ORDER ="pro-id-previous-order"
export const DATE__DISPLAY_FORMAT = "dd/MM/yyyy"
export const DATE_TIME_FORMAT_FOR_DISPLAY ="dd/MM/yyyy hh:mm:ss a"
export const TIME_FORMAT_IN_AM_OR_PM="hh:mm:ss a"

// export const STRIPE_PUBLIC_KEY= process.env.NODE_ENV === "production" ? "pk_live_51LIXItBdMP7k6Pe4ZQtGbCwQHatMUeTXMyD2wGIpb3vSOnonwNJAUiTG3Xdga17" : "pk_test_51Lmd4RK4iriIjbiz2sSddNv1bJ4pxPfJGpi983qnNeVB4ermUQeTk7FwdfBVGOS4On6J8ly2hgDeuq1gOWBZ7faC000BOq09DU"
export const STRIPE_PUBLIC_KEY= "pk_test_51Lmd4RK4iriIjbiz2sSddNv1bJ4pxPfJGpi983qnNeVB4ermUQeTk7FwdfBVGOS4On6J8ly2hgDeuq1gOWBZ7faC000BOq09DU"



export const CONFIRMED="confirmed"
export const SHIPPED='shipped'
export const CLOSED='closed'
export const PENDING="pending"
export const CANCELLED='cancelled'