import { createSlice } from '@reduxjs/toolkit';


const initialState = {
}



const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        store_checkout_data:(state,action)=>{
            state =  action.payload
            return state
        },
        set_city: (state,action)=>{
            state.shipping_city_id=action.payload
            return state
        },
        reset_checkout_data:(state,action)=>{
            return initialState   
        }

    }
})

export const {store_checkout_data,set_city,set_quantity,reset_checkout_data} = checkoutSlice.actions;
export default checkoutSlice.reducer;
