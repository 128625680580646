import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./redux/store";
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'  // with  npm install @jonkoops/matomo-tracker-react
import { MATOMO_SITE_ID, MATOMO_URL, MATOMO_USER_ID } from './constant';

// const instance = createInstance({
//   urlBase: MATOMO_URL,
//   siteId: MATOMO_SITE_ID,
//   userId: MATOMO_USER_ID , // optional, default value: `undefined`.
//   disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
// })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store = {store}> 
    {/* <MatomoProvider value={instance}> */}
      <App />
    {/* </MatomoProvider> */}
    </Provider>
  // </React.StrictMode>
);

