import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    
}



const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        set_coupon_code:(state,action)=>{
            state = action.payload
            return state
        },
        reset_coupon_code:(state,action)=>{
            state=initialState
            return state
        }

    }
})

export const {set_coupon_code,reset_coupon_code} = cartSlice.actions;
export default cartSlice.reducer;
