import React,{useState} from 'react'
import BaseRoutes from "./routes"
import { ReactNotifications } from "react-notifications-component";
import { BrowserRouter } from 'react-router-dom';
//global popup
import BasePopup, { BasePopupContext } from './components/BasePopup';
//global popup


const App = () => {
   // state for popup
   const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({ ...old, ...params }));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }
  // state for popup


  return (
    <>
    <BasePopupContext.Provider value={global_popup_context_data}>
      <BrowserRouter>
        <ReactNotifications />
        <BaseRoutes/>
      </BrowserRouter>
      <BasePopup/>
    </BasePopupContext.Provider>
    </>
  )
}

export default App