import { createSlice } from '@reduxjs/toolkit';


const initialState = [
    {
    quantity:1,
    product_code:"basic",
    price:1200,
    subtotal:1200,
    coupon_code:""
    }

]



const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        set_quantity: (state,action)=>{
            state[0].quantity=action.payload
            return state
        },
        set_coupon_code:(state,action)=>{
            state[0].coupon_code = action.payload
            return state
        },
        reset_cart_data:(state,action)=>{
            state=initialState;
            return state
        }

    }
})

export const {store_checkout_data,set_city,set_quantity,set_coupon_code,reset_cart_data} = cartSlice.actions;
export default cartSlice.reducer;
