import { configureStore } from "@reduxjs/toolkit";

// slice imports
import configuratorReducer from "./slices/configuratorSlice";
import three_D_configuratorReducer from "./slices/3dConfiguratorSlice";
import checkoutReducer from "./slices/checkoutSlice";
import cartReducer from "./slices/cartSlice";
import couponCodeReducer from "./slices/couponCodeSlice";
import countryReducer from "./slices/countrySlice";

// slice imports

export default configureStore({
    reducer:{
        configurator:configuratorReducer,
        three_D_configurator:three_D_configuratorReducer,
        checkout_data:checkoutReducer,
        cart:cartReducer,
        couponCode:couponCodeReducer,
        country_listing:countryReducer,
    },
    devTools: process.env.NODE_ENV === 'development' ? true:false,
})
