import { createSlice } from "@reduxjs/toolkit";
import { BLACK, WHITE } from "../../constant";


const initialState = {
    show3DConfigurator:false,
    threeD_frontSide:'',
    threeD_backSide:'',
};

const three_d_configuratorSlice = createSlice({
    name:"3d Configurator Slice",
    initialState,
    reducers:{
        set_show3DConfigurator:(state,action)=>{
            state.show3DConfigurator = action.payload
            return state
        },
        set_threeD_frontSide:(state,action)=>{
            state.threeD_frontSide = action.payload
            return state
        },
        set_threeD_backSide:(state,action)=>{
            state.threeD_backSide = action.payload
            return state
        },
        reset_three_d_configurator:()=>{return initialState}
    }
})

export const {
    set_show3DConfigurator,
    set_threeD_frontSide,
    set_threeD_backSide,
    reset_three_d_configurator
} = three_d_configuratorSlice.actions

export default three_d_configuratorSlice.reducer;