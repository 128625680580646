import { createSlice } from "@reduxjs/toolkit";
import { BLACK, WHITE } from "../../constant";


const initialState = {
    text:"Your Name",
    textSize:50,
    designationStyle:'',
    frontImage:"",
    backImage:"",
    designation:"",
    backgroundColor:BLACK,
    qrBlockColor:WHITE,
    nfcColor:WHITE,
};

const configuratorSlice = createSlice({
    name:"Configurator Slice",
    initialState,
    reducers:{
        set_text:(state,action)=>{
            state.text = action.payload
            return state
        },
        set_designation:(state,action)=>{
            state.designation = action.payload
            return state
        },
        set_front_Image:(state,action)=>{
            state.frontImage = action.payload
            return state
        },
        set_back_Image:(state,action)=>{
            state.backImage = action.payload
            return state
        },
        set_background_color:(state,action)=>{
            state.backgroundColor = action.payload
            return state
        },
        set_text_size:(state,action)=>{
            state.textSize=action.payload
            return state
        },
        set_designation_style:(state,action)=>{
            state.designationStyle=action.payload
            return state
        },
        set_qrBlockColor:(state,action)=>{
            state.qrBlockColor = action.payload
            state.nfcColor = action.payload
            return state
        },
        reset_configurator:()=>{return initialState}
    }
})

export const {
    set_text,
    set_front_Image,
    set_back_Image,
    set_background_color,
    set_qrBlockColor,
    set_text_size,
    reset_configurator,
    set_designation,
    set_designation_style
} = configuratorSlice.actions

export default configuratorSlice.reducer;